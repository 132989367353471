import {
    Drawer,
    Badge
} from 'antd';
import { useState, FC, useEffect } from 'react'
import { dispatch } from 'store/store'
import { ButtonUpper } from '../ui-elements/Buttons'
import { MessagesListing } from '../ui-elements/Comment'
import { useSelector } from 'hooks/useSelector'
import styled from 'styled-components'
import { MessageResponse } from 'store/kyc-front/slices/messagesSlice'
import { messagesGet } from 'store/kyc-front/asyncThunks/messagesGet'
import { companyNotesGet } from 'store/kyc-backend/asyncThunks/companyNotesGet';
import { individualNotesGet } from 'store/kyc-backend/asyncThunks/individualNotesGet';

const ButtonGroupItem = styled(ButtonUpper)`
    width: 100%;
`

const DrawerCustom = styled(Drawer)`
   .ant-drawer-content-wrapper {
        max-height: 100vh;
    }
`

const CustomBadge = styled(Badge)`
    z-index: 9;
    display: contents;
    .ant-badge-count {
        padding: 1px 6px;
        
    }
    .ant-btn-dashed {
        padding: 0;
        height: 33px;
        font-size: 14px;
    }
`

type MessageType = 'note' | 'message';

interface DrawerProps {
  type: MessageType
}

const FooterDrawer: FC<DrawerProps> = ({type}) => {
    const [visible, setVisible] = useState(false);
    const envs = useSelector((state) => state.general.envs);
    const { messages } = useSelector((state) => state.admin.adminInbox);
    const notes = useSelector((state) => state.admin.backNotes);
    const { kycApplication } = useSelector((state) => state.user2.kycApplication)
    const [dataLoaded, setDataLoaded] = useState(false);
    const [messageData, setMessageData] = useState<MessageResponse[]>([])
    let isBusiness = envs.accountType === 'BUSINESS'
    let isNote = type === 'note'
    useEffect(() => {
        if(dataLoaded === false && envs.clientId !== '') {
            if(isBusiness) {
                dispatch(companyNotesGet({ params: { 'companyId': envs.profileId } }))
            } else {
                dispatch(individualNotesGet({ params: { 'individualId': envs.profileId } }))
            }
            setDataLoaded(true);
        }
    }, [envs.accountType]);

    useEffect(() => {
        if(messages.length) {
            setMessageData(messages.filter(message => message.companyId === envs.profileId || message.individualId === envs.profileId))
        }
    }, [messages]);
    
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  let nameEl = kycApplication.values.name || 'not set'
  return (
    <>
        {type === 'note' &&
            <ButtonGroupItem btnType="text" block onClick={showDrawer}>Notes</ButtonGroupItem>
        }
        {type === 'message' &&
            <CustomBadge count={messageData.filter((messageEl: MessageResponse) => !messageEl.viewedByAdmin && !messageEl.createdBy).length}>
                <ButtonGroupItem btnType="dashed" className='dashed-primary' block onClick={showDrawer}><span>Messages</span></ButtonGroupItem>
            </CustomBadge>
        }
        <DrawerCustom height={500} title={type === 'note' ? "Notes on KYC Client " + nameEl: "Messages to Client " + nameEl} placement="bottom" onClose={onClose} visible={visible}>
            <MessagesListing 
                type={type}
                side='admin'
                listing={isNote ? isBusiness ? notes.companyNotes : notes.individualNotes : messageData}
            />
        </DrawerCustom>
    </>
  );
};


export default FooterDrawer;