import { useSelector } from '../../../hooks/useSelector'
import { dispatch } from '../../../store/store'
import { FC, useEffect} from 'react'
import styled from 'styled-components'
import { themeColor } from '../../../styles/themeStyles'
import KycLayout, { NavItem } from '../kyc-layout/KycLayout'
import { profileGet } from  '../../../store/kyc-front/asyncThunks/profileGet'
import { individualGet } from '../../../store/kyc-front/asyncThunks/individualGet'
import { MessagesListing } from '../ui-elements/Comment'
import { messagesGet } from 'store/kyc-front/asyncThunks/messagesGet';

const CommentCustom = styled.div`
	margin-top: -40px;
	.ant-list-header {
		text-align: right;
		color: ${themeColor.gray}
	}
	.ant-list-items {
		.ant-comment {
	        border-bottom: 1px solid ${themeColor.grayWhite};
	        &:last-child{
	        	border: none;
	        }
	    }
	}
    
`

const KycInbox: FC = () => {
  	const envs = useSelector((state) => state.general.envs);
  	const messages = useSelector((state) => state.user2.messages);
    let isBusiness = envs.accountType === 'BUSINESS';
  	useEffect(() => {
			dispatch(messagesGet({params: {clientId: envs.clientId}}));
      		if(isBusiness) {
                dispatch(profileGet({params: {companyId: envs.profileId}}));
      		} else {
                dispatch(individualGet());
      		}
  	}, []);
	return (
		<KycLayout nav={{prev: envs.admin ? '/summary' : `/`, step: -1, prevName: "Back to KYC", navPage: NavItem.CLIENT_INBOX, hideSteps: true}}>
			<>
				<CommentCustom>
                    <MessagesListing 
                        type='message' 
                        side='client'
                        listing={messages.profileMessages}
                     />
				</CommentCustom>
			</>
		</KycLayout>
			 
	)
}

export default KycInbox
