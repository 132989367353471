import { createAsyncThunk } from "@reduxjs/toolkit";
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "api/kyc/generated/client2";

type MessageResponse = Components.Schemas.InternalMessage;
type RequestBody = Paths.CreateMessage.RequestBody

export const messageCreate: AsyncThunkActionType<
  MessageResponse,
  AsyncThunkDataType<undefined, RequestBody, "data">
> = createAsyncThunk("createMessage", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api2.createMessage(data?.params, data.data, data?.config);
    return response.data as MessageResponse;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
