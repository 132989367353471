import { createSlice } from "@reduxjs/toolkit"
import { Components } from "api/kyc/generated/client2"
import { CaseReducerType } from "../../store"
import { ClientActionPoint, clientsActionPointsGet } from '../asyncThunks/clientsActionPointsGet'
import { clientsGet } from "../asyncThunks/clientsGet"
import { clientDelete } from "../asyncThunks/clientDelete"

export type ClientSummary = Components.Schemas.ClientSummary

type State = {
    clientsList: ClientSummary[],
	clientsSearchListing: ClientSummary[],
	clientDeleted: boolean
	clientsActionPoints: ClientActionPoint[]
};


const initialState: State = {
    clientsList: [],
	clientsSearchListing: [],
	clientDeleted: false,
	clientsActionPoints: [],
};

type CaseReducers<State> = {
    clientsGetClear: CaseReducerType<State, object>;
	clientDeleteClear: CaseReducerType<State, object>;
};

export const clientsSlice = createSlice<State, CaseReducers<State>>({
	name: "clientsSlice",
	initialState,
	reducers: {
		clientsGetClear: (state, {payload}) => {
			state.clientsList = initialState.clientsList
			state.clientsSearchListing = initialState.clientsSearchListing
		},
		clientDeleteClear: (state, {payload}) => {
			state.clientDeleted = initialState.clientDeleted
		}
	},
	extraReducers: (builder) => {
		builder.addCase(clientsGet.fulfilled, (state, action) => {
			let query = action.meta.arg?.params?.query
			if(query && query !== '') {
				state.clientsSearchListing = action.payload
			} else {
				state.clientsList = action.payload
				state.clientsSearchListing = []
			}
		});
		builder.addCase(clientsActionPointsGet.fulfilled, (state, action) => {
			state.clientsActionPoints = action.payload
		});
		builder.addCase(clientDelete.fulfilled, (state, action) => {
			state.clientDeleted = true
		});
	},
});

export const { clientsGetClear, clientDeleteClear } = clientsSlice.actions;
export default clientsSlice.reducer;
