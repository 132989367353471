import auth, { AuthState } from 'modules/keycloak/store/authSlice'
import envs from "./kyc-front/slices/envsSlice";
import identity from "./kyc-front/slices/identitySlice";
import user from "./kyc-front/slices/userSlice";

import individual from "./kyc-front/slices/individualSlice";
import profile from "./kyc-front/slices/profileSlice";
import device from "./kyc-front/slices/deviceSlice";
import messages from "./kyc-front/slices/messagesSlice";
import nav from "./kyc-front/slices/navSlice";
import errorsState from "./kyc-front/slices/errorsSlice";
import responseInfo from './kyc-front/slices/responseInfo'
import forbidden from './kyc-front/slices/forbiddenSlice'

import backCompany from "./kyc-backend/slices/companySlice";
import backCompanyDocs from "./kyc-backend/slices/companyDocsSlice";
import backIndividual from "./kyc-backend/slices/individualSlice";
import backIndividualDocs from "./kyc-backend/slices/individualDocsSlice";
import approved from "./kyc-backend/slices/approvedSlice";
import admin from "./kyc-backend/slices/adminSlice";
import adminInbox from "./kyc-backend/slices/adminInboxSlice";
import archivedSlice from "./kyc-backend/slices/archivedSlice";
import backNotes from "./kyc-backend/slices/notesSlice";
import backIdentity from "./kyc-backend/slices/identitySlice";
import businessRelation from "./kyc-backend/slices/businessRelationSlice";
import businessProfiles from "./kyc-backend/slices/businessProfileSlice";
import fxAccount from "./kyc-backend/slices/fxAccountSlice";
import riskProfile from "./kyc-backend/slices/riskSlice";
import clients from './kyc-backend/slices/clientsSlice';
import approvalErrors from './kyc-backend/slices/kycApprovalErrors';

//API V2 CLIENT
import kycApplication from "./kyc-front/slices/kycApplicationSlice"
import kycApplicationChanges from "./kyc-front/slices/kycApplicationChangesSlice"
//API V2 ADMIN
import applicationApprovals from "./kyc-backend/slices/backKycApplication";

import { persistReducer, persistCombineReducers } from 'redux-persist'
import { combineReducers } from 'redux';
import storage from "redux-persist/lib/storage";


const authWhiteList: (keyof AuthState)[] = ['session', 'backToUri']
const authConfig = {
	key: 'auth',
	storage,
	whitelist: authWhiteList as string[],
}


const generalReducer = combineReducers({
  auth: persistReducer(authConfig, auth),
  envs,
  identity,
  user,
  device,
  responseInfo,
  nav,
  forbidden,
  errorsState
})

const adminReducer = combineReducers({
  backCompany,
  backCompanyDocs,
  backIndividual,
  backIndividualDocs,
  approved,
  admin,
  adminInbox,
  archivedSlice,
  backNotes,
  backIdentity,
  businessRelation,
  fxAccount,
  riskProfile
});

const userReducer = combineReducers({
  profile,
  individual
});

const user2Reducer = combineReducers({
  kycApplication,
  kycApplicationChanges,
  messages
});

const admin2Reducer = combineReducers({
  applicationApprovals,
  approvalErrors,
  businessProfiles,
  clients
})

const slices = {
  general: generalReducer,
  user: userReducer,
  user2: user2Reducer,
  admin: adminReducer,
  admin2: admin2Reducer
}

export default slices;
