import { Comment } from '@ant-design/compatible';
import { Form, Input, message, Row, Col, Tabs, Table, Select, Divider } from 'antd'
import Text from 'antd/es/typography/Text'
import { moment } from '../ui-elements/FormDate'
import React, { useState, FC, useEffect, ReactNode } from 'react'
import { dispatch, AsyncThunkActionType, CaseReducerType } from 'store/store'
import { adminMessagesGet } from 'store/kyc-backend/asyncThunks/adminMessagesGet'
import { ButtonUpper } from './Buttons'
import Well from './Well'
import { useSelector } from 'hooks/useSelector'
import { useActionStatus, ResponseInfo } from 'hooks/useActionStatus'
import styled from 'styled-components'
import { themeColor } from 'styles/themeStyles'
import { camelCaseToName, nameParse } from 'utilities/nameParsers'
import FormSelect from './FormSelect'
import { messageCreate } from 'store/kyc-front/asyncThunks/messageCreate'
import { MessageResponse, messageCreateClear, messageViewedPutClear } from 'store/kyc-front/slices/messagesSlice';
import { messagesGet } from 'store/kyc-front/asyncThunks/messagesGet';
import { messageViewedPut } from 'store/kyc-front/asyncThunks/messageViewedPut';
import { ClientSummary } from 'store/kyc-backend/slices/clientsSlice';
import { AdminResponse } from 'store/kyc-backend/slices/adminSlice';
import { companyNotePost } from 'store/kyc-backend/asyncThunks/companyNotePost';
import { companyNotePostClear, individualNotePostClear } from 'store/kyc-backend/slices/notesSlice';
import { companyNotesGet } from 'store/kyc-backend/asyncThunks/companyNotesGet';
import { individualNotesGet } from 'store/kyc-backend/asyncThunks/individualNotesGet';
import { individualNotePost } from 'store/kyc-backend/asyncThunks/individualNotePost';

const { TextArea } = Input;
const { TabPane } = Tabs;

const WellCustom = styled(Well)`
    background-color: ${themeColor.grayOpaque}
`

export const PreEl = styled.pre`
    display: 'block';
    padding: '10px 30px';
    margin: '0';
    overflow: 'scroll';
`

const CommentCustom = styled(Comment)`
    .ant-comment-inner {
        padding-bottom: 0;
    }
`

const CommentItemStyled = styled.div`
    padding: 15px 10px;
    margin-left: -10px;
    margin-right: -10px;
    border-bottom: 1px solid ${themeColor.grayWhite};
    h3 {
        font-size: 15px;
        overflow-x: hidden;
    }
    &.unread {
        font-weight: bold;
        background-color: ${themeColor.blueWhite}
    }
    &:last-child {
        border-bottom: 0;
    }
    .date {
        color: ${themeColor.gray};
        font-size: 12px;
        margin-bottom: 10px;
    }
`

const TabsCustom = styled(Tabs)`
    .ant-tabs-nav {
        margin-bottom: 0;
    }
    .ant-tabs-tab {
        padding-left: 15px;
        padding-right: 15px;
    }
    .ant-tabs-nav-list {
        width: 100%;
    }
    .ant-tabs-tab-disabled {
        flex-grow: 1;
        text-align: right;
        .ant-tabs-tab-btn {
            width: 100%;
        }
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${themeColor.primaryColor};
        text-shadow: 0 0 0.25px ${themeColor.primaryColor};
    }
    .ant-tabs-nav-operations {
        display: none!important;
    }
    .ant-tabs-ink-bar {
        background-color: ${themeColor.primaryColor};
    }
`

const TableCustom = styled(Table)`
    thead {
        //display: none;
    }
    .ant-table-row.unread {
        font-weight: bold;
        .ant-table-cell {
            background-color: #fafafa;
        }
    }
    .ant-table-cell {
        &.hidden {
            //display: none;
        }
    }
`

const InputCustom = styled(Input)`
    height: 40px
`

type MessageType = 'note' | 'message';
type SideType = 'client' | 'admin'
type PersonType = 'BUSINESS' | 'PRIVATE' | undefined;
type ActionType = AsyncThunkActionType<any, any>;
type ClearActionType = CaseReducerType<any, null>;

export interface CommentItem {
    key: string,
    id: string,
    author: string;
    adminAuthor?: string,
    subject: string;
    isRead?: boolean;
    isUserMessage?: boolean;
    isExpanded: boolean,
    handleRead?: any;
    content: React.ReactNode;
    contentShort: string;
    datetime: React.ReactNode;
    messageAccountId?: string;
    messageAccountType?: 'company' | 'individual'
}

interface EditorProps {
    onSubmit: () => void;
    submitting: boolean;
    type: MessageType,
    side?: SideType,
    writeTo?: any,
    adminInbox?: boolean
}

interface MessagesListingProps {
    type: MessageType, 
    side?: SideType,
    listing: MessageResponse[], 
    adminInbox?: boolean
}

const columnsNotes = [
    {
        title: 'Author',
        dataIndex: 'author',
        key: 'author',
        fixed: 'left',
        render: (v: any) => <Text ellipsis={{tooltip:v}} style={{maxWidth: '165px'}}>{v}</Text>,
        width: '200px'
    },
    {
        title: 'Message',
        dataIndex: 'contentShort',
        key: 'contentShort',
    },{
        title: 'Date',
        dataIndex: 'datetime',
        key: 'datetime',
        width: '180px',
    },
    Table.EXPAND_COLUMN
];

const columnsUser = [
    {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
        fixed: 'left',
        render: (v: any) => <Text ellipsis={{tooltip:v}} style={{maxWidth: '165px'}}>{v}</Text>,
        width: '200px',
    },
      {
        title: 'Message',
        dataIndex: 'contentShort',
        key: 'contentShort',
    },{
        title: 'Date',
        dataIndex: 'datetime',
        key: 'datetime',
        width: '180px',
    },
    Table.EXPAND_COLUMN
];



const columns = (received: boolean) => ([
    {
        title: 'Author',
        dataIndex: received ? 'author' : 'adminAuthor',
        key: 'author',
        fixed: 'left',
        render: (v: any) => <Text ellipsis={{tooltip:v}} style={{maxWidth: '165px'}}>{v}</Text>,
        width: '200px'
    },
    ...columnsUser
]);

const columnsSent = (received: boolean) => ([
    {
        title: 'To',
        dataIndex: 'author',
        key: 'author',
        fixed: 'left',
        render: (v: any) => <Text ellipsis={{tooltip:v}} style={{maxWidth: '165px'}}>{v}</Text>,
        width: '200px'
    },
    ...columns(received)
]);

export const CommentList = ({ comments, type, received, admin, userMessages, adminInbox, handleRead }: { comments: CommentItem[], received: boolean, type: MessageType, admin?: boolean, userMessages?: boolean, adminInbox?: boolean, handleRead: any }) => {
    let columnsRender;
    if(admin) {
        if(type === 'note') {
            columnsRender = columnsNotes
        } else {
            if(adminInbox) {
                if(received) {
                    columnsRender = columns(received)
                } else {
                    columnsRender = columnsSent(received)
                }
            } else {
                if(received) {
                    columnsRender = columnsUser
                } else {
                    columnsRender = columns(received)
                }
            }
        }
        
    } else {
        columnsRender = columnsUser
    }
    return ( 

        <TableCustom
            dataSource={comments}
            columns={columnsRender}
            rowClassName={(record, index) => {
                //@ts-ignore
                if (!record.isRead) {
                  return "unread";
                } else {
                    return ""
                }
            }}
            pagination={{ showSizeChanger: false }} 
            expandable={{
                expandedRowRender: comment => (
                    <Row justify="space-between" style={{padding: '10px 0'}}>
                        <Col>
                            {/*@ts-ignore*/}
                            <h4 style={{ maxWidth: 1200 }}>{comment.subject}</h4>
                            {/*@ts-ignore*/}
                            <div style={{ margin: 0, maxWidth: 1200, whiteSpace: "pre-line"}}>{comment.content}</div>
                        </Col>
                    </Row>
                ),
                expandIcon: ({ expanded, onExpand, record }) => {
                    const handleExpand = (record: object, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                        handleRead(record, expanded);
                        onExpand(record, e);
                    }
                    return (
                        <div style={{width: '75px'}}>
                        {expanded ? (
                            <ButtonUpper className='btn-sm' btnType='default' onClick={e => handleExpand(record, e)} block>Close</ButtonUpper>
                        ) : (
                            <ButtonUpper className='btn-sm' btnType='default' onClick={e => handleExpand(record, e)} block>Read</ButtonUpper>
                        )}
                        </div>
                    )
                },
                //@ts-ignore
                rowExpandable: comment => comment.content !== undefined,
            }}
          />
    )
};

const Editor = ({ onSubmit, submitting, type, side, writeTo, adminInbox}: EditorProps) => {
    const [value, setValue] = useState('');
    const [subject, setSubject] = useState('');
    const [select, setSelect] = useState({id: '', type: ''});
    const [refreshSelect, setRefreshSelect] = useState(false);
    const envs = useSelector((state) => state.general.envs);
    const {clientsList} = useSelector((state) => state.admin2.clients);
    const { Option } = Select
    const isNote = type === 'note'
    const isMessage = type === 'message'
    let isBusiness = envs.accountType === 'BUSINESS'
    useEffect(() => {
        if(refreshSelect === true) {
            setRefreshSelect(false);
        }
       
    }, [refreshSelect]);
    const handleSubmit = () => {
        if (!value ) return;
        if(isMessage) {
            dispatch(messageCreate({ data: {clientId: envs.clientId !== '' ? envs.clientId : select.id, subject: subject, message: value} }))
        }
        if(isNote) {
            if(isBusiness) {
                dispatch(companyNotePost({params: {companyId: envs.profileId}, data: { message: value}}))
            } else {
                dispatch(individualNotePost({ params: { individualId: envs.profileId }, data: { message: value} }))
            }
            

        }
        setValue('');
        setSubject('');
        setSelect({id: '', type: ''});
        setRefreshSelect(true);
        onSubmit();
    };
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
    };
    const handleRecipientChange = (el: string, e: any) => {
        setSelect({id: el, type: e.type});
    }
    const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSubject(e.target.value);
    };
    return (
      <>
        {adminInbox &&
            <div style={{marginBottom: 15, marginTop: -15}}>
                <Divider />
                <h2>New message</h2>
                {refreshSelect === false &&
                    <FormSelect label='' defaultValue={select.id} type='company' id='recipient' placeholder='To' onChange={(val, e) => handleRecipientChange(val, e)} disabled={false} hideApprove={true}  >
                        {[...clientsList].map((user, i) => {
                            const isCompany = user.accountType === 'COMPANY'
                            return <Option type={isCompany ? 'BUSINESS' : 'PRIVATE'} key={'user' + i} value={user.profileId}>{user.name ? user.name : user.profileId}</Option>
                            
                        })}
                    </FormSelect>
                }
            </div>
        }
        {isMessage &&
            <Form.Item>
                <InputCustom name='subject' placeholder='Subject' onChange={handleSubjectChange} value={subject} />
            </Form.Item>
        }
        <Form.Item>
            <TextArea name='text' rows={4} onChange={handleChange} value={value} />
        </Form.Item>
        <Form.Item style={{'marginBottom':"5px"}}>
            <ButtonUpper htmlType="submit" onClick={handleSubmit} btnType="primary">
                {side === 'client' ? (
                        <>Send message to Admin</>
                    ) : (
                        <>{isNote ? 'Add Note' : 'Send message to Client'}</>
                    )
                }
            </ButtonUpper>
        </Form.Item>
      </>
    )
};

export const getSubject = (message: any) => {
    let subject = '';
    let messageEl: string | ReactNode = '';
    let messageExtract;
    let messageUpdateText = 'has been updated by Bitclear Admin. Please check it and if all is correct, submit whole KYC to us once again by clicking "Submit to us" on last step of KYC';
    if(message.subject.startsWith('update.') || message.subject.startsWith('update')) {
        if(message.subject.startsWith('update')) {
            subject = 'Bitclear Admin has updated Your profile'
            messageEl = 'Your profile ';
        } else {
            let subjectElems = message.subject.split('.');
            subjectElems.forEach((elem: any) => {
                switch(elem) {
                    case 'update':
                        subject = 'Bitclear Admin has updated '
                        break
                    case 'company':
                        subject += 'Company '
                        messageEl = 'Your company profile ';
                        break
                    case 'ubo': 
                        subject += 'UBO '
                        messageEl = 'UBO profile ';
                        break
                    case 'ap':
                        subject += 'Authorized Person '
                        messageEl = 'Authorized Person profile '
                        break
                    default:
                        subject += elem
                }
                
            })
        }
        
        messageEl += messageUpdateText;
        messageEl += message.message;
        messageExtract = messageEl;
    }  else if (message.subject === "Your KYC was updated, verify changes." || message.subject === 'Your KYC profile is updated, please verify changes in your profile.' || message.subject.startsWith('Beneficial Owner profile -') || message.subject.startsWith('Authorized person profile -')) {
        subject = message.subject
        messageEl = <><p>Your profile  {messageUpdateText}:</p><p><div style={{'marginLeft': 15}}><WellCustom>{getMessage(message.message)}</WellCustom></div></p></>;
        messageExtract = 'Your profile ' + messageUpdateText;
    } else {
        subject = message.subject
        messageEl = message.message
        messageExtract = messageEl;
    }
    let el = {
        subject: subject,
        messageEl: messageEl,
        messageExtract: messageExtract
    }
    return el
}

export const getMessage = (message: any) => {
    let jsonMessage = JSON.parse(message);
    let elems: ReactNode[] = [];
    Object.entries(jsonMessage).map((elem, i) => {
        let value = elem[1];
        if(typeof value !== "object") {
            if(value !== '') {
                elems.push(<p key={i}><span>{nameParse(elem[0])}: </span><b>{(typeof value === 'boolean') ? <>{String(value)}</> : <>{value}</>}</b></p>)
            }
        } else {
            //@ts-ignore
            Object.entries(value).map((elemInner, ind) => {
                let valueInner = elemInner[1];
                if(typeof valueInner !== "object") {
                    if(valueInner !== '') {
                        elems.push(<p key={i + ',' + ind}><span>{nameParse(elemInner[0])}: </span><b>{(typeof valueInner === 'boolean') ? <>{String(valueInner)}</> : <>{valueInner}</>}</b></p>)
                    }
                } else {
                    //@ts-ignore
                    Object.entries(valueInner).map((elemThird, ind2) => {
                        let valueThird = elemThird[1];
                        if(valueThird !== '') {
                            elems.push(<p key={i + ',' + ind + ',' + ind2}><span>{nameParse(elemThird[0])}: </span><b>{(typeof valueThird === 'boolean') ? <>{String(valueThird)}</> : <>{valueThird}</>}</b></p>)
                        }
                    })
                }
                
            })
        }
        
    })
    return elems;
}


export const MessagesListing: FC<MessagesListingProps> = ({type, side, listing, adminInbox}) => {
    const [comments, setComments] = useState<CommentItem[]>([]);
    const [showEditor, setShowEditor] = useState(false);
    const [userMessages, setUserMessages] = useState<CommentItem[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const [expanded, setExpanded] = useState<string[]>([]);
    const [writeTo, setWriteTo] = useState<'BUSINESS' | 'PRIVATE' | ''>(adminInbox ? 'BUSINESS' : '');
    const { clientsList } = useSelector((state) => state.admin2.clients);
    const envs = useSelector((state) => state.general.envs);
    const admin = useSelector((state) => state.admin.admin);
    const wasViewed = useSelector((state) => state.user2.messages.messageViewed)
    const msgSent = useSelector((state) => state.user2.messages.messageSent)
    const individualNote = useSelector((state) => state.admin.backNotes.individualNote)
    const companyNote = useSelector((state) => state.admin.backNotes.companyNote)
    let isBusiness = envs.accountType === 'BUSINESS'
    const noteSent = isBusiness ? companyNote : individualNote
    const msgPosting = useActionStatus(messageCreate);
    const notePosting = useActionStatus(isBusiness ? companyNotePost : individualNotePost)
    const isNote = type === 'note'
    const isMessage = type === 'message'
    

    const populateMessages = (messagesStateListing: MessageResponse[]) => {
            let messagesListing:CommentItem[] = [];
            let userMessagesListing:CommentItem[] = [];
            let messagesResponse = [];
            if(adminInbox) {
                let messagesList = [...messagesStateListing]
                messagesResponse = messagesList.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt))).reverse();
            } else {
                messagesResponse = [...messagesStateListing].sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt))).reverse();
            }
            messagesResponse.forEach((message) => {
                let subject = getSubject(message);
                let isExpandedValue = expanded.find((el) => el === message.messageId) ? true : false;
                let isRead = envs.admin ? message.viewedByAdmin ? true : false : message.viewedAt ? true : false;
                let messageAccountId = message.clientId
                //let messageAccountId = adminInbox ? message.companyId ? message.companyId : message.individualId ? message.individualId : undefined : undefined;
                //let contentShort = subject?.messageExtract?.length > 70 ? subject.messageExtract.substring(0,70) + '...' : message.message;
                let contentShort = subject.messageExtract?.toString()
                let nameID = message.clientId ? message.clientId : ''
                let name: string | undefined = ''
                let adminName; 
                if(adminInbox) {
                    if(message.clientId) {
                        name = clientsList.find((elem: ClientSummary) => message.clientId === elem.id)?.name
                    }
                }
                if(admin) {
                    adminName = admin.profiles.find((elem: AdminResponse) => elem.adminId === message.createdBy)
                }
                if(message.createdBy) {
                    messagesListing.push({
                        key: message.messageId,
                        id: message.messageId,
                        messageAccountId,
                        subject: subject.subject,
                        author: name ? name : nameID,
                        adminAuthor: adminName?.username ? camelCaseToName(adminName.username) : 'Admin',
                        isRead: envs.admin ? true : isRead,
                        isExpanded: isExpandedValue,
                        contentShort: contentShort ? contentShort : '',
                        content: subject.messageEl,
                        datetime: <span>{moment(message.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>,
                    });
                } else {
                    userMessagesListing.push({
                        key: message.messageId,
                        id: message.messageId,
                        messageAccountId,
                        subject: subject.subject,
                        author: name ? name : nameID,
                        adminAuthor: adminName?.username ? camelCaseToName(adminName.username) : 'Admin',
                        isRead: !envs.admin ? true : isRead,
                        isUserMessage: true,
                        isExpanded: isExpandedValue,
                        contentShort: contentShort ? contentShort : '',
                        content: subject.messageEl,
                        datetime: <span>{moment(message.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>,
                    })
                }
            })
            setComments(messagesListing);
            setUserMessages(userMessagesListing);
            setSubmitting(false);
    }
    const populateNotes = (notesStateListing: any[] | {companyMessages: any[], individualMessages: any[]} ) => {
        let messagesListing:CommentItem[] = [];
        //@ts-ignore
        let messagesResponse = [...notesStateListing].sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt))).reverse();
        messagesResponse.forEach((message, i) => {
            let name = admin.profiles.find((elem) => elem.adminId === message.createdBy);
            let isExpandedValue = expanded.find((el) => el === message.messageId) ? true : false;
            let messageAccountType: 'company' | 'individual' | undefined = adminInbox ? message.companyId ? 'company' : message.individualId ? 'individual' : undefined : undefined;
            let messageAccountId = adminInbox ? message.companyId ? message.companyId : message.individualId ? message.individualId : undefined : undefined;
            let contentShort = message.note?.length > 140 ? message.note.substring(0,140) + '...' : message.note;
            //let contentShort = message.message?.length > 140 ? message.message.substring(0,140) + '...' : message.message;
            messagesListing.push({
                key: '' + i,
                id: message.messageId,
                messageAccountId,
                subject: '',
                author: name?.username ? camelCaseToName(name.username) : 'Admin',
                isRead: true,
                isExpanded: isExpandedValue,
                messageAccountType: messageAccountType,
                contentShort: contentShort,
                content: message.note,
                datetime: <span>{moment(message.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>,
            })
        })
        setComments(messagesListing);
        setSubmitting(false);
    }
    const handleRead = (record: CommentItem) => {
        const {isRead, messageAccountId} = record;
        if(isRead === false) {
            dispatch(messageViewedPut({params: { messageId: record.id, clientId: adminInbox ? messageAccountId : undefined}}));
        }
    }
    useEffect(() => {
        setSubmitting(true);
        if(isNote && listing) {
            populateNotes(listing);
        }
        if(isMessage && listing) {
            populateMessages(listing);
        }
    }, [listing])

    useEffect(() => {
        if(isMessage) {
            if(!msgPosting.isPending) {
                handleSent(msgPosting, msgSent, messageCreateClear)
            }
        }
    }, [msgSent])

    useEffect(() => {
        if(isNote) {
            if(!notePosting.isPending) {
                handleSent(notePosting, noteSent, isBusiness ? companyNotePostClear : individualNotePostClear)
            }
        }
    }, [noteSent])

    useEffect(() => {
        if(wasViewed && (wasViewed.read === 204) && !isNote) {
            if(adminInbox) {
                dispatch(messageViewedPutClear({ payload: null }));
                dispatch(adminMessagesGet({params: {}}));
            } else {
                dispatch(messageViewedPutClear({ payload: null }));
                if(envs.admin) {
                    dispatch(adminMessagesGet({params: {}}));
                } else {
                    dispatch(messagesGet({params: {clientId: envs.clientId}}));
                }
            }
          }
    }, [wasViewed]);

    const handleSent = (posting: ResponseInfo, elem: boolean, clearAction?: ClearActionType) => {
        if(posting.isFullfilled || posting.isError) {
            if(isMessage) {
                if(elem) {
                    if(posting.isFullfilled ) {
                        message.success('Message was successfully sent');
                        if(posting.isFullfilled && clearAction) {
                            dispatch(clearAction(null, { payload: null, type: '' }));
                        }
                        if(adminInbox) {
                            dispatch(adminMessagesGet({params: {}}))
                        } else {
                            if(envs.admin) {
                                dispatch(adminMessagesGet({params: {}}))
                            } else {
                                dispatch(messagesGet({ params: { clientId: envs.clientId } }))
                            }
                        }
                    }
                } else {
                    if(posting.isError) {
                        message.error('Some error occurred');
                        if(posting.isError && clearAction) {
                            dispatch(clearAction(null, { payload: null, type: '' }));
                        }
                    }
                    
                }
            }
            if(isNote) {
                if(elem) {
                    if(posting.isFullfilled) {
                        message.success('Note was successfully saved');
                        if(posting.isFullfilled && clearAction) {
                            dispatch(clearAction(null, { payload: null, type: '' }));
                        }
                        if(isBusiness) {
                            dispatch(companyNotesGet({ params: { companyId: envs.profileId } }))
                        } else {
                            dispatch(individualNotesGet({ params: { individualId: envs.profileId } }))
                        }
                        
                    }
                    
                } else {
                    if(posting.isError) {
                        message.error('Some error occurred');
                        if(posting.isError && clearAction) {
                            dispatch(clearAction(null, { payload: null, type: '' }));
                        }
                    }
                    
                }
            }
        }
       
    }
    const handleSubmit = () => {
        setSubmitting(true)
    }
    useEffect(() => {
        if(listing) {
            if(isMessage) {
                populateMessages(listing);
            }
            if(isNote) {
                populateNotes(listing);
            }
        }
        
    }, [expanded]);
    const toggleEditor = () => {
        setShowEditor(!showEditor);
    }
    let unreadLength = 0;
    if(adminInbox) {
        unreadLength = userMessages.filter(el => !el.isRead).length;
    } else {
        unreadLength = comments.filter(el => !el.isRead).length;
    }
    let passProps = {
        handleRead: handleRead,
        type: type,
        admin: envs.admin,
        adminInbox: adminInbox
    }
    return (
        <>
          <CommentCustom
            avatar={false}
            content={
                <>{showEditor || (envs.admin && !adminInbox) ? (
                    <Editor
                        onSubmit={handleSubmit}
                        submitting={submitting}
                        type={type}
                        side={side}
                        writeTo={writeTo}
                        adminInbox={adminInbox}
                    />) : (<></>)}
                </>
            }
          />      
            {isNote ? (
                <>{comments.length > 0 && <CommentList {...passProps} received={false} comments={comments} />}</>
            ) : (
                <TabsCustom defaultActiveKey="1">
                    <TabPane tab="Received messages" key="1">
                        {envs.admin ? (
                            <>{userMessages.length > 0 ? <CommentList {...passProps} received={true} comments={userMessages} userMessages={true} />:<></>}</> 
                        ) : (
                            <>{comments.length > 0 ? <CommentList {...passProps} received={true} comments={comments} />:<></>}</>
                        )}
                    </TabPane>
                    <TabPane tab="Sent messages" key="2">
                        {envs.admin ? (
                            <>{comments.length > 0 ? <CommentList {...passProps} received={false} comments={comments} />:<></>}</>
                        ) : (
                            <>{userMessages.length > 0 ? <CommentList {...passProps} received={false} comments={userMessages} userMessages={true} />:<></>}</>
                        )}
                    </TabPane>
                    {(!envs.admin || adminInbox) &&
                        <TabPane tab={<>
                            <span>{unreadLength} </span><span style={{'marginRight':'10px'}}>{unreadLength > 1 || unreadLength === 0 ? 'unread messages' : 'unread message'}{envs.admin ? '' : ' from Bitclear Admin'}</span>
                            <ButtonUpper className='btn-sm' btnType='primary' onClick={toggleEditor} ghost>{showEditor ? 'Hide new message' : 'New message'}</ButtonUpper>
                            </>} disabled key='3'>
                        </TabPane>
                    }
                </TabsCustom>
            )}
        </>
    );
};
