import { createSlice } from "@reduxjs/toolkit";
import { CaseReducerType } from "../../store";
import { messagesGet } from "../asyncThunks/messagesGet";
import { Components, Paths } from "api/kyc/generated/client2";
import { messageCreate } from "../asyncThunks/messageCreate";
import { messageViewedPut } from "../asyncThunks/messageViewedPut";

export type MessageResponse = Components.Schemas.InternalMessage;
type MessageViewedResponse = Paths.SetMessageAsViewed.Responses.$204;

type State = {
  	profileMessages: MessageResponse[],
  	messageViewed: {id: string, read: MessageViewedResponse},
  	messageSent: boolean
};

const initialState: State = {
  	profileMessages: [],
  	messageViewed: {id: '', read: -1},
  	messageSent: false
};

type CaseReducers<State> = {
	messageViewedPutClear: CaseReducerType<State, object>;
  	messageCreateClear: CaseReducerType<State, object>;
  	messagesGetClear: CaseReducerType<State, object>;
};

export const messagesSlice = createSlice<State, CaseReducers<State>>({
  name: "messages",
  initialState,
  reducers: {
    messageCreateClear: (state, { payload }) => {
      	state.messageSent = initialState.messageSent
    },
    messagesGetClear: (state, { payload }) => {
      	state.profileMessages = initialState.profileMessages
    },
	messageViewedPutClear: (state, { payload }) => {
		state.messageViewed = initialState.messageViewed
  },
  },
  extraReducers: (builder) => {
    builder.addCase(messagesGet.fulfilled, (state, action) => {
        state.profileMessages = action.payload;
    });
    builder.addCase(messageCreate.fulfilled, (state, action) => {
      	state.messageSent = true;
    });
	builder.addCase(messageViewedPut.fulfilled, (state, action) => {
		state.messageViewed = action.payload;
  	});
  },
});

export const { messageCreateClear, messagesGetClear, messageViewedPutClear } = messagesSlice.actions;
export default messagesSlice.reducer;
