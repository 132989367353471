import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "api/kyc/api"
import { AsyncThunkDataType } from "store/common"
import { Paths } from "api/kyc/generated/client2"
import { AsyncThunkActionType } from "store/store"

type Response = Paths.SetMessageAsViewed.Responses.$204;

interface QueryParameters {
    id?: string;
    clientId?: string;
    messageId: string
}
type ResponseBody = {id: string, read: Response};

export const messageViewedPut: AsyncThunkActionType<
    ResponseBody,
    AsyncThunkDataType<QueryParameters, null, "params">
> = createAsyncThunk(
    "messageViewedPut",
    async ({ params, data, config }, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await api2.setMessageAsViewed(params, data, config);
            return {id: params?.messageId, read: response.status} as ResponseBody;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);
