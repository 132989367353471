import { useSelector } from 'hooks/useSelector'
import { useActionStatus } from 'hooks/useActionStatus'
import { setEnvs } from 'store/kyc-front/slices/envsSlice'
import { FC, ReactNode, useEffect } from 'react'
import { identityGet } from 'store/kyc-backend/asyncThunks/identityGet'
import { CoverPage } from 'modules/kyc-front/ui-elements/CoverPage'
import { Navigate, useParams } from 'react-router-dom'
import { dispatch } from 'store/store'
import { NavItem } from 'modules/kyc-front/kyc-layout/KycLayout'

const RedirectIfNoUser: FC<{ children?: ReactNode }> = ({ children }) => {
    let { id } = useParams()
    const backIdentity = useSelector((state) => state.admin.backIdentity);
    const envs = useSelector((state) => state.general.envs);
    const gettingProfileStatus = useActionStatus(identityGet);
    const { apiURL, env, type, routeSuffix, adminSuffix } = envs;
    useEffect(() => {
		if((envs.profileId == '' || backIdentity.identity.id == '') && !gettingProfileStatus.isPending && !gettingProfileStatus.isFullfilled) {
			if(id) {
                dispatch(identityGet({params: {identityId: id}}));
            }
		}
	}, []);
    useEffect(() => {
		if(backIdentity.identity.id !== '' && envs.profileId === '') {
			dispatch(setEnvs({apiURL, admin: true, adminSuffix: adminSuffix,  type: type, env: env, routeSuffix: routeSuffix, profileId: backIdentity.identity.id, clientId: backIdentity.identity.id, accountType: backIdentity.identity.kind === 'company' ? 'BUSINESS' : 'PRIVATE'}));
		}
	}, [backIdentity]);
    if((backIdentity.identity.id == '' && gettingProfileStatus.isFullfilled) || gettingProfileStatus.isError) {
        return <Navigate to={routeSuffix + '/' +  NavItem.CLIENT_PROFILES} replace />
    } else {
        if (!gettingProfileStatus.isFullfilled) {
            return <CoverPage visible={true} />
        }
        return <>{children}</>
    }
	
}
export const UserAdminRoute: FC<{ dynamic?: boolean, children: ReactNode }> = ({ children }) => {
    return (
		<RedirectIfNoUser>
			{children}
		</RedirectIfNoUser>
	)
}
