import Title from 'antd/es/typography/Title'
import Text from 'antd/es/typography/Text'
import { Col, Row, Spin } from 'antd'
import { profileGet } from '../../../store/kyc-front/asyncThunks/profileGet'
import { individualGet } from '../../../store/kyc-front/asyncThunks/individualGet'
import { useSelector } from '../../../hooks/useSelector'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { dispatch } from '../../../store/store'
import { FC, useEffect, useState } from 'react'
import { userDisplayedStatuses } from '../../../utilities/nameParsers'
import styled from 'styled-components'
import { KycDocumentsSteps } from '../KycDocumentsSteps';
import KycNextSteps from '../KycNextSteps'
import { themeColor, themeSize } from '../../../styles/themeStyles'
import KycLayout, { NavItem } from '../kyc-layout/KycLayout'
import KycPrint from '../KycPrint'
import { SuccessIcon, QuestionIcon, ErrorIcon } from '../ui-elements/SuccessInfo'
import SyncSwitch from 'assets/SyncSwitch.svg'
import Well from '../ui-elements/Well'
import { ButtonUpper } from '../ui-elements/Buttons'
import { Link } from 'react-router-dom'
import DiscardChangesModal from '../user-actions/DiscardChangesModal'
import { kycApplicationChangesDiscard } from 'store/kyc-front/asyncThunks/kycApplicationChangesDiscard'


const TextMain = styled(Text)`
	font-size: ${themeSize.base};
	line-height: 32px;
`

const TextCustom = styled(Text)`
	&.ant-typography.ant-typography-success {
		color: ${themeColor.green}!important;
	}
`

const KycIntro: FC = () => {
	const profile = useSelector((state) => state.user.profile)
	const individual = useSelector((state) => state.user.individual)
	const envs = useSelector((state) => state.general.envs)
	const { stepsScheme, kycApplication } = useSelector((state) => state.user2.kycApplication)
  	const companyGetStatus = useActionStatus(profileGet)
  	const individualGetStatus = useActionStatus(individualGet)
  	const status = kycApplication.status;
  	const approved = status === 'ACCEPTED'
	const clarify = status === 'CLARIFICATION_NEEDED'
	const rejected = status === 'REJECTED'
	const [showDiscardModal, setShowDiscardModal] = useState(false)
	const displayedStatus = userDisplayedStatuses(status)
	const changesDiscardStatus = useActionStatus(kycApplicationChangesDiscard)
	const review = displayedStatus === 'SUBMITTED'
	const changed = displayedStatus === 'BEING_CHANGED'
	const keys = Object.keys(kycApplication.values)
	const nextName = kycApplication.status === 'NEW' && keys.length === 1 && keys[0] === 'id' ? 'Begin your application' : 'Continue'
  	const isActiveIndividual = individual?.individual?.businessRelation?.isActive
	const isActiveCompany = profile?.userProfile?.profile?.businessRelation?.isActive
	useEffect(() => {
  		if(envs.isBusiness) {
  			dispatch(profileGet({ params: { companyId: envs.profileId} }))
  		} else {
  			dispatch(individualGet())
  		}
    }, []);
	useEffect(() => {
		if(changesDiscardStatus.isError || changesDiscardStatus.isFullfilled) {
			setShowDiscardModal(false)
		}
  }, [changesDiscardStatus]);
	const processMessage = (text: string) => {
		const trimmedText = text.replace(/^"(.*)"$/, '$1')
		return trimmedText.replace(/\\n/g, '\n')
	};
	const discardChanges = () => {
		setShowDiscardModal(true)
	}
	const handleDiscardChanges = () => {
		dispatch(kycApplicationChangesDiscard({params: {id: envs.profileId}}))
	}
	return (
		<KycLayout nav={{next: review ? undefined : stepsScheme[0].link, step: 0, nextName: nextName, nextButton: "primary", status: status, navPage: NavItem.CLIENT_PROFILE, hideSteps: true}}>
			{changed ? (
				<div style={{textAlign: 'center'}}>
					<Row style={{marginBottom: 40, marginTop: 40, justifyContent: 'center'}}>
						<div>
							<img src={SyncSwitch} alt='Edited' style={{width: 200, height: 200}} />
						</div>
					</Row>
					<Title level={4}>
					You have pending changes to your profile
					</Title>
					<br />
					<label>Once you're done please submit your Application or discard changes </label>
					<Row style={{justifyContent: 'center', marginTop: 50}}>
						<ButtonUpper ghost btnType="primary" style={{marginRight: 10}} onClick={discardChanges}>Discard</ButtonUpper>
						<Link to={envs.admin ? `${envs.routeSuffix}/${NavItem.CLIENT_PROFILES}/${envs.profileId}${stepsScheme[stepsScheme.length - 1].link}` : `${envs.routeSuffix}/${NavItem.CLIENT_PROFILE}${stepsScheme[stepsScheme.length - 1].link}`}><ButtonUpper btnType="primary">Submit</ButtonUpper></Link>
					</Row>
					<br />
				</div>
			) : (
				<>
					{(!isActiveIndividual && !isActiveCompany && status !== 'NEW') || ((isActiveCompany || isActiveIndividual) && review) ? (
						<Col span={24} style={{'marginBottom': '20px', 'paddingLeft': '0', 'marginTop': '0', textAlign: displayedStatus === 'NEW' ? 'left' : 'center'}}>
							{displayedStatus === 'NEW' &&
								<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>Your KYC application is 
									<TextCustom type="secondary"><b> partially filled </b></TextCustom>
								</Text>
							}
							{review &&
								<>
									<div style={{marginBottom: 40}}>
										<SuccessIcon />
									</div>
									<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>Your application is complete and pending our review</Text>
								</>
							}
						</Col>
					) : (<></>)}
					{companyGetStatus.isFullfilled || individualGetStatus.isFullfilled ? (
						<>
							{(isActiveIndividual || isActiveCompany) && !review && !clarify ? (
								<>
									<Col span={24} style={{'marginBottom': '20px', 'paddingLeft': '0'}}>
										<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>Business Relation: 
											<TextCustom type='success'><b> Active </b></TextCustom>
										</Text>
									</Col>
									<Title level={4}>
										Your Bitclear Applications are Active
									</Title>
									<TextMain>
										We've received Your documents and approved them<br />
										All functionalities of Bitclear Applications have been unlocked for You<br />
										<br />
									</TextMain>
									<KycPrint />
								</>
							) : (
								<>
									<>
										{review ? (
											<>
												<Title level={4} style={{textAlign: 'center'}}>
													We will contact you as soon as it is done
												</Title>
												
												<KycNextSteps />
											</>
										) : (
											<>
												{approved ? (
													<>
														<div style={{marginBottom: 40}}>
															<SuccessIcon />
														</div>
														<Title level={4} style={{textAlign: 'center'}}>
															Your KYC is approved and ready.
														</Title>
														<TextMain>
															If you have not already done so, download the approved KYC, print it, sign it and send it along with <b>all the uploaded documents</b> by post or courier to the following address:<br />
															<br />
															Bitclear Aktiengesellschaft<br />
															PO Box 534<br />
															Industriestrasse 26<br />
															9491 Ruggell, Liechtenstein<br />
														</TextMain>
														<KycPrint />
													</>

													) : (
													<>
														{clarify ? (
															<>
																<div style={{marginBottom: 40}}>
																	<QuestionIcon />
																</div>
																<Title level={4} style={{textAlign: 'center'}}>
																	Your application requires some changes
																</Title>
																<br />
																<label>Admin requested changes in your KYC application: </label>
																<br />
																{kycApplication.message &&
																	<>
																	<Col>
																		<Well>
																			<pre style={{marginBottom: 0}}>{processMessage(kycApplication.message)}</pre>
																		</Well>
																	</Col>
																	</>
																}
																<br />
																<label>After changes are done please submit your KYC once again</label>
																<br />
															</>
														) : (
															<>
															{rejected ? (
																<>
																	<div style={{marginBottom: 40}}>
																		<ErrorIcon />
																	</div>
																	<Title level={4} style={{textAlign: 'center'}}>
																		Your application has been rejected
																	</Title>
																	<p style={{textAlign: 'center'}}>Please contact us if you have some question</p>
																	<br />
																</>

															) : (
																<>
																	<div>
																		<TextMain>
																			To protect our clients we have implemented a strict "Know Your Client" process. In order to go through the process smoothly, please prepare the below-mentioned documents.
																		</TextMain>
																	</div>
																	<KycDocumentsSteps />
																	<Title level={5}>Please be aware that you need to send the original documents to Bitclear AG.</Title>
																</>
															)}
															</>
														)}
													</>
												)}
											
											</>
										)}
									</>
								</>
							)}
							
						</>
					) : (
						<Spin size='large' />
					)}
				</>
			)}
			<DiscardChangesModal open={showDiscardModal} handleOk={handleDiscardChanges} handleCancel={() => setShowDiscardModal(false)} />
		</KycLayout>
			 
	)
}

export default KycIntro
