import { createAsyncThunk } from "@reduxjs/toolkit";
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "api/kyc/generated/client2";

type MessagesResponse = Components.Schemas.InternalMessage

interface QueryParameters {
  	clientId?: string;
  	id?: string
}

export const messagesGet: AsyncThunkActionType<
  MessagesResponse[],
  AsyncThunkDataType<QueryParameters, null, "params">
> = createAsyncThunk("getMessages", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api2.getMessages(data?.params, null, data?.config);
    return response.data as MessagesResponse[];
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
