import { createSlice } from "@reduxjs/toolkit";
import { adminMessagesGet } from "../asyncThunks/adminMessagesGet";
import { CaseReducerType } from "../../store";
import { Components } from "api/kyc/generated/client2";

export type MessagesResponse = Components.Schemas.InternalMessage

type State = {
  	messages: MessagesResponse[]
};

const initialState: State = {
  	messages: []
};

type CaseReducers<State> = {
  	adminMessagesGetClear: CaseReducerType<State, object>;
};

export const adminInboxSlice = createSlice<State, CaseReducers<State>>({
	name: "adminInbox",
	initialState,
	reducers: {
		adminMessagesGetClear: (state, { payload }) => {
			state.messages = initialState.messages
		}
	},
	extraReducers: (builder) => {
		builder.addCase(adminMessagesGet.fulfilled, (state, action) => {
			state.messages = action.payload;
		})
	},
});

export const { adminMessagesGetClear } = adminInboxSlice.actions;
export default adminInboxSlice.reducer;
