import { Space, Row, Col, Layout, Badge, Menu, Dropdown, message } from 'antd'
import type { MenuProps } from 'antd'
import _ from 'lodash'
import Title from 'antd/es/typography/Title'
import { CaretLeftOutlined, EllipsisOutlined } from '@ant-design/icons'
import Text from 'antd/es/typography/Text'
import { FC, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { logout, logoutUser } from 'modules/keycloak'
import { setEditedFormConfirmed, setNav } from 'store/kyc-front/slices/navSlice'
import { setEnvs } from 'store/kyc-front/slices/envsSlice'
import { kycApplicationValidateClear } from 'store/kyc-front/slices/kycApplicationSlice'
import { companyDocsGetClear as companyDocsGetBackClear} from 'store/kyc-backend/slices/companyDocsSlice'
import { adminMessagesGet } from 'store/kyc-backend/asyncThunks/adminMessagesGet'
import { useSelector } from 'hooks/useSelector'
import { dispatch } from 'store/store'
import { Components } from 'api/kyc/generated/client'
import { PageContent } from 'modules/layout/PageContent'
import { ButtonUpper } from 'modules/kyc-front/ui-elements/Buttons'
import { adminProfileGet } from 'store/kyc-backend/asyncThunks/adminProfileGet'
import { adminProfilesGet } from 'store/kyc-backend/asyncThunks/adminProfilesGet'
import { adminProfileGetClear } from 'store/kyc-backend/slices/adminSlice'
import { profileGetClear } from 'store/kyc-front/slices/profileSlice'
import { individualGetClear } from 'store/kyc-front/slices/individualSlice'
import { identityGetClear } from 'store/kyc-front/slices/identitySlice'
import { useActionStatus } from 'hooks/useActionStatus'
import { camelCaseToName } from 'utilities/nameParsers'
import Error403 from 'modules/kyc-front/Error403'
import FooterDrawer from 'modules/kyc-front/kyc-layout/KycFooterDrawer'
import { KycSteps } from 'modules/kyc-front/kyc-layout/KycSteps'
import KycMessages from 'modules/kyc-messages/KycMessages'
import Logo from 'assets/logo-kyc.png'
import { ADMIN_BASE_PATH, BASE_PATH } from '../../../routes/AppRoutes'

import { FormSaver } from 'utilities/FormSaver'

import { KycNav } from 'modules/kyc-front/kyc-layout/KycNav'
import { themeColor, themeSize, themeScreen } from 'styles/themeStyles'
import { kycApplicationSendBack } from 'store/kyc-backend/asyncThunks/kycApplicationSendBack'
import { kycApplicationGet } from 'store/kyc-front/asyncThunks/kycApplicationGet'
import { kycApplicationRejectClear, kycApplicationSendBackClear } from 'store/kyc-backend/slices/backKycApplication'
import { ENV } from "index"
import { clientDelete } from 'store/kyc-backend/asyncThunks/clientDelete'
import { clientDeleteClear } from 'store/kyc-backend/slices/clientsSlice'
import { useNavigate, NavLink } from 'react-router-dom'
import RejectKYCModal from 'modules/kyc-backend/admin-actions/RejectKyc'
import DeleteClientModal from 'modules/kyc-backend/admin-actions/DeleteClient'
import SendBackModal from 'modules/kyc-backend/admin-actions/SendBack'
import { kycApplicationReject } from 'store/kyc-backend/asyncThunks/kycApplicationReject'
import DiscardChangesModal from '../user-actions/DiscardChangesModal'
import { kycApplicationChangesDiscard } from 'store/kyc-front/asyncThunks/kycApplicationChangesDiscard'
import { kycApplicationChangesDiscardClear, kycApplicationChangesGetClear } from 'store/kyc-front/slices/kycApplicationChangesSlice'
import { kycApplicationChangesGet } from 'store/kyc-front/asyncThunks/kycApplicationChangesGet'
import { kycApplicationValidate } from 'store/kyc-front/asyncThunks/kycApplicationValidate'
const { Footer, Header } = Layout


const PageName = styled(Text)`
	color: ${themeColor.primaryColor};
	font-size: ${themeSize.heading3};
	font-weight: 500;
`
const Sticky = styled.div`
	position: -webkit-sticky;
  	position: sticky;
  	z-index: 9;
  	&.no-stick {
  		position: relative;
  	}
`
const Fixed = styled.div`
  	position: fixed;
  	z-index: 10;
	width: 100%;
	.content {
		max-width: ${themeScreen.maxContent};
	}
  	&.no-stick {
  		position: relative;
		bottom: 0!important;
  	}
`
export const ImgLogo = styled.img`
	max-width: 265px;
	margin-left: -45px;
`

export const HeaderCustom = styled(Header)`
	position: fixed;
	position: sticky;
	z-index: 99;
	width: 100%;
	top: 0;
	border-bottom: 1px solid ${themeColor.grayWhite};
	.content {
		margin: auto;
		max-width: ${themeScreen.maxContent};
	}
`
export const FooterCustom = styled(Footer)`
	width: 100%;
	border-top: 1px solid ${themeColor.grayWhite};
	.content {
		margin: auto;
		max-width: ${themeScreen.maxContent};
	}
`

const FooterCustomModule = styled(Footer)`
	padding: 0;
	max-width: ${themeScreen.maxContent};
`

export const NavCustom = styled.div`
	border-bottom: 1px solid ${themeColor.grayWhite};
	position: fixed;
	position: sticky;
	z-index: 99;
	width: 100%;
	top: 65px;
	background-color: #fff;
	padding-top: 0;
	padding-bottom: 0;
	.content {
		margin: auto;
		max-width: ${themeScreen.maxContent};
	}
	p {
		margin-bottom: 0;
		padding-top: 3px;
		color: ${themeColor.gray};
		.client {
			color: #000;
		}
	}
`

export const NavCustomTabs = styled(NavCustom)`
	top: 108px;
	padding-top: 0;
	padding-bottom: 0;
`

export const ButtonUpperCustom = styled.span`
	.btn-sm {
		font-size: 14px;
		font-weight: 500;
		padding-left: 0;
		color: ${themeColor.gray};
	}
`

const ButtonUpperTabs = styled(ButtonUpper)`
	&.btn-sm {
		color: ${themeColor.gray};
		padding-top: 8px;
		padding-bottom: 8px;
		&.active {
			color: ${themeColor.primaryColor};
			background-color: ${themeColor.grayWhite};
		}
	}
`

type ReviewStatusType = Components.Schemas.ReviewStatusType

interface IProps {
	approve?: boolean,
	archive?: boolean,
	restore?: boolean,
	listingView?: boolean,
	pageName?: string,
	heading?: string,
	adminView?: boolean,
	children: ReactNode,
	validationAction?: (value: any) => void,
	validationActionParams?: object,
	hideValidate?: boolean,
	hideSaveButton?: boolean,
	hideApproveStepButton?: boolean,
	setErrorsListing?: (value: any) => any,
	nav: {
		next?: string,
		step?: number,
		substep?: number,
		refId?: string,
		nextName?: string,
		nextButton?: string,
		prev?: string,
		prevName?: string,
		status?: ReviewStatusType | undefined,
		hideNav?: boolean,
		hideSteps?: boolean,
		navPage?: NavItem,
		navSubpage?: string,
		link?: string
	}
}

type AProps = {
	statusInfo: string,
	businessRelation: boolean,
	handleDropMenuClick: (value: any) => void
}


const MenuCustom = styled(Menu)`
	position: relative;
	top: -1px;
	&.ant-menu-dark {
		background: #fff;
		.ant-menu-item {
			padding: 0;
			a {
				padding: 0 16px;
				display: block;
				color: #000;
				font-weight: bold;
				&.active {
					background-color: #f6f6f6;
					color: ${themeColor.primaryColor};
				}
				&:hover:not(.active) {
					background-color: #f6f6f6;
					color: #000;
				}
			}
		} 
	}
	
	
`
export enum NavItem {
	// admin
	ACTION_POINTS = 'action-points',
	CLIENT_PROFILES = 'client-profiles',
	ARCHIVED = 'archived',
	MESSAGES = 'messages',
	
	// client
	CLIENT_PROFILE = 'profile',
	CLIENT_INBOX = 'inbox'
}

const items = (count: number, isAdmin?: boolean) => {
	if(isAdmin) {
		return ([
			{
				label: <NavLink to={`${ADMIN_BASE_PATH}/${NavItem.ACTION_POINTS}`} className={({isActive}) => isActive ? "active" : ""}>Action points</NavLink>,
				key: NavItem.ACTION_POINTS,
			},
			{
				label: <NavLink to={`${ADMIN_BASE_PATH}/${NavItem.CLIENT_PROFILES}`}>Client profiles</NavLink>,
				key: NavItem.CLIENT_PROFILES,
			},
			{
				label: <NavLink to={`${ADMIN_BASE_PATH}/${NavItem.MESSAGES}`}>Messages <Badge count={count} /></NavLink>,
				key: NavItem.MESSAGES
			}
		])
	} else {
		return ([
			{
				label: <NavLink to={`${BASE_PATH}/${NavItem.CLIENT_PROFILE}`}>KYC Profile</NavLink>,
				key: NavItem.CLIENT_PROFILE,
			},
			{
				label: <NavLink to={`${BASE_PATH}/${NavItem.CLIENT_INBOX}`}>Messages <Badge count={count} /></NavLink>,
				key: NavItem.CLIENT_INBOX
			}
		])
	}
}

const getActionItems = (statusInfo: string, businessRelation: boolean): MenuProps['items'] => [
	{
	  	label: 'Send KYC Back to Client',
	  	key: 'back',
		disabled: statusInfo !== 'SUBMITTED'
	},
	{
		label: 'Discard KYC Changes',
		key: 'discard',
		disabled: statusInfo !== 'BEING_CHANGED'
	},
	{
		label: 'Reject KYC',
		key: 'reject',
		disabled: (businessRelation === true)|| statusInfo !== 'SUBMITTED' && statusInfo !== 'NEW' && statusInfo !== 'CLARIFICATION_NEEDED'
	},
	{
		label: 'Delete Client',
		key: 'delete',
		danger: true,
		disabled: statusInfo !== 'NEW'
  	},
]

const ActionMenuDropdown: FC<AProps> = ({statusInfo, businessRelation, handleDropMenuClick}) => {
	const actionItems = getActionItems(statusInfo, businessRelation);
	const onClick: MenuProps['onClick'] = (e) => {
		handleDropMenuClick(e)
	}
	return (
		<span style={{position: 'relative', left: 5, top: 3}}>
			<Dropdown menu={{items: actionItems, onClick: onClick}} trigger={['click']} >
				<ButtonUpper btnType="default" className='btn-sm'>
					<EllipsisOutlined />
				</ButtonUpper>
			</Dropdown>
		</span>
	)
}

export const KycLayout: FC<IProps> = ({
	pageName, heading, adminView, children, nav, validationAction, validationActionParams, approve, archive, restore, listingView, hideValidate, hideSaveButton, hideApproveStepButton, setErrorsListing
}) => {
	let {next, step, substep, refId, nextName, nextButton, prev, prevName, status, hideNav, hideSteps, navPage, navSubpage, link} = nav
	const navigate = useNavigate()
	const envs = useSelector((state) => state.general.envs)
	const {kycApplication} = useSelector((state) => state.user2.kycApplication)
	const {profileMessages} = useSelector((state) => state.user2.messages)
	const profile = useSelector((state) => envs.admin ? state.admin.backCompany.profile : state.user.profile.userProfile)
	const individualProfile = useSelector((state) => envs.admin ? state.admin.backIndividual.individual : state.user.individual.individual)
	const {apiURL, admin, adminSuffix, type, env, routeSuffix, clientId} = envs
	const adminProfile = useSelector((state) => state.admin.admin)
	const adminInbox = useSelector((state) => state.admin.adminInbox)
	const navEl = useSelector((state) => state.general.nav)
	const { session } = useSelector((state) => state.general.auth)
	const adminProfileLoaded = useActionStatus(adminProfileGet)
   	const [navSearch, setNavSearch] = useState('')
   	const statusInfo = kycApplication.status
   	const relationStatus = envs.accountType === 'BUSINESS' ? profile?.profile?.businessRelation?.isActive : individualProfile?.businessRelation?.isActive
	const [showSendBackModal, setShowSendBackModal] = useState(false)
	const [showDiscardModal, setShowDiscardModal] = useState(false)
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [rejectModalOpen, setRejectModalOpen] = useState(false)
   	const profileArchived = profile?.profile?.archivedAt || individualProfile?.archivedAt ? true : false
   	const adminMessagesGetStatus = useActionStatus(adminMessagesGet)
	const kycApplicationSendBackStatus = useActionStatus(kycApplicationSendBack)
	const deletingClientStatus = useActionStatus(clientDelete)
	const kycRejectingStatus = useActionStatus(kycApplicationReject)
	const changesDiscardStatus = useActionStatus(kycApplicationChangesDiscard)
   	let messagesCount = 0
   	if(adminInbox?.messages?.length) {
		messagesCount = adminInbox.messages.filter((messageEl) => !messageEl.viewedByAdmin && !messageEl.createdBy).length
   	}
	if(!envs.admin && profileMessages?.length) {
		messagesCount = profileMessages.filter(messageEl => !messageEl.viewedAt && messageEl.createdBy).length
   	}
   	const onClick: MenuProps['onClick'] = e => {
	    dispatch(setNav({next, step, substep, refId, nextName, nextButton, prev, prevName, status, hideNav, navPage: e.key, paginationPage: navEl.nav.paginationPage, sorters: navEl.nav.sorters, filters: navEl.nav.filters, navSubpage, link: link, activeListingTab: navEl.nav.activeListingTab}))
		dispatch(setEditedFormConfirmed({onConfirm: true, onConfirmLink: '/' + e.key, general: true, showErrors: false, refId}))
	}
	useEffect(() => {
		dispatch(setNav({next, step, substep, refId, nextName, nextButton, prev, prevName, status, hideNav, navPage: nav.navPage ? nav.navPage : navEl.nav.navPage, paginationPage: navEl.nav.paginationPage, sorters: navEl.nav.sorters, filters: navEl.nav.filters, navSubpage, link: link, activeListingTab: navEl.nav.activeListingTab, query: navEl.nav.query}))
		if(envs.admin && type === 'standalone') {
			if(!adminMessagesGetStatus.isFullfilled) {
				dispatch(adminMessagesGet({params: {}}))
			}
			const interval = setInterval(() => {
				dispatch(adminMessagesGet({params: {}}))
			}, 60000)
			return () => clearInterval(interval)
		}
	}, [])
	useEffect(() => {
		dispatch(setNav({next, step, substep, refId, nextName, nextButton, prev, prevName, status, hideNav, navPage: navPage ? navPage : navEl.nav.navPage, paginationPage: navEl.nav.paginationPage, sorters: navEl.nav.sorters, filters: navEl.nav.filters, navSubpage, link: link, activeListingTab: navEl.nav.activeListingTab, query: navEl.nav.query}))
	}, [status, prev, next, step, navPage, status])
	useEffect(() => {
		if(envs.admin) {
			 if(adminProfile.profile.adminId === '') {
            dispatch(adminProfileGet({params: null}))
         }
         if(adminProfile.profiles.length === 0) {
            dispatch(adminProfilesGet({params: null}))
         }
		}
    }, [adminProfile])
    useEffect(() => {
    	let navSearchNew = navEl.nav.paginationPage ? '?page=' + navEl.nav.paginationPage : ''
	    if(navEl.nav.sorters) {
	    	_.forEach(navEl.nav.sorters, (val, name) => {
	    		if(val) {
	    			let prefix = navSearchNew !== '' ? '&' : '?'
	    			navSearchNew += prefix + name + '=' + val
	    		}
	    	})
	    }
	    if(navEl.nav.filters) {
	    	_.forEach(navEl.nav.filters, (val, name) => {
	    		if(val) {
	    			let prefix = navSearchNew !== '' ? '&' : '?'
	    			navSearchNew += prefix + name + '=' + val
	    		}
	    	})
	    }
		if(navEl.nav.query) {
			let prefix = navSearchNew !== '' ? '&' : '?'
	    	navSearchNew += prefix + 'query' + '=' + navEl.nav.query
		}
	    setNavSearch(navSearchNew)
    }, [navEl.nav])
	useEffect(() => {
		if(kycApplicationSendBackStatus.isFullfilled) {
			message.success('Profile was sent back to Client')
			dispatch(kycApplicationGet({params: {id: envs.profileId}}))
			dispatch(kycApplicationSendBackClear({}))
		}
		if(kycApplicationSendBackStatus.isError) {
			message.error('Some error occurred while sending back to Client')
			dispatch(kycApplicationSendBackClear({}))

		}
	}, [kycApplicationSendBackStatus])
	useEffect(() => {
		if(deletingClientStatus.isFullfilled) {
			message.success('This Client was successfully deleted')
			dispatch(clientDeleteClear({}))
			let tabEl = navEl.nav.activeListingTab ?  navEl.nav.activeListingTab : ''
			let searchEl = navSearch ? navSearch : ''
			navigate(adminSuffix + '/' + NavItem.CLIENT_PROFILES + '/' + tabEl + searchEl)
		}
		if(deletingClientStatus.isError) {
			message.error('Some error occurred while deleting this Client')
			dispatch(clientDeleteClear({}))

		}
	}, [deletingClientStatus])
	useEffect(() => {
		if(kycRejectingStatus.isFullfilled) {
			message.success('This KYC Application was successfully rejected')
			dispatch(kycApplicationRejectClear({}))
			dispatch(kycApplicationGet({params: {id: envs.profileId}}))
		}
		if(kycRejectingStatus.isError) {
			message.error('Some error occurred while rejecting this application')
			dispatch(kycApplicationRejectClear({}))

		}
	}, [kycRejectingStatus])
	useEffect(() => {
		if(changesDiscardStatus.isError || changesDiscardStatus.isFullfilled) {
			setShowDiscardModal(false)
			if(changesDiscardStatus.isError) {
				message.error('Some error occurred, please try again')
			} else {
				message.success('Changes were discarded successfully')
				dispatch(kycApplicationChangesDiscardClear({}))
				dispatch(kycApplicationGet({params: {id: envs.profileId}}))
				dispatch(kycApplicationChangesGet({params: {id: envs.profileId}}))
				dispatch(kycApplicationValidate({params: {id: envs.profileId}}))
			}
		}
  }, [changesDiscardStatus]);
	const handleLogoutClick = () => {
		if(envs.isBusiness) {
			if(envs.admin) {
				dispatch(adminProfileGetClear({}))
				dispatch(companyDocsGetBackClear({}))
			} else {
				dispatch(identityGetClear({}))
				dispatch(profileGetClear({}))
			}
			
		} else {
			if(envs.admin) {
				dispatch(adminProfileGetClear({}))
			} else {
				dispatch(identityGetClear({}))
				dispatch(individualGetClear({}))
			}
		}
		dispatch(kycApplicationValidateClear({}))
		dispatch(kycApplicationChangesGetClear({}))
		dispatch(setEnvs({apiURL, admin, adminSuffix, type, env, routeSuffix, profileId: '', clientId: '', accountType: ''}))
		onLogout()
	}
	const onLogout = async () => {
		await logoutUser({
			refreshToken: session?.refresh_token,
			keycloakDomain: ENV.KEYCLOAK_DOMAIN,
			client_id: envs.admin ? ENV.KEYCLOAK_CLIENT_BACK : ENV.KEYCLOAK_CLIENT_FRONT,
			//@ts-ignore
			is_backend: envs.admin,
			callback: () => dispatch(logout(true))
		})
	}
	const handleDeleteConfirm = () => {
		dispatch(clientDelete({params: {clientId: envs.profileId}}))
		setDeleteModalOpen(false)
	}

	const handleDeleteCancel = () => {
		setDeleteModalOpen(false)
	}
	const handleRejectConfirm = ({shouldSendEmail = false, wording = ''}) => {
		dispatch(kycApplicationReject({params: {id: envs.profileId}, data: {shouldSendEmail, emailWording: wording}}))
		setRejectModalOpen(false)
	}
	const handleRejectCancel = () => {
		setRejectModalOpen(false)
	}
	const handleDropMenuClick = (e: any) => {
		if(e.key === 'back') {
			setShowSendBackModal(true)
		}
		if(e.key === 'discard') {
			setShowDiscardModal(true)
		}
		if(e.key === 'reject') {
			setRejectModalOpen(true)
		}
		if(e.key === 'delete') {
			setDeleteModalOpen(true)
		}
	}
	const handleSendBack = (descr: string) => {
		dispatch(kycApplicationSendBack({params: {id: envs.profileId}, data: descr}))
		setShowSendBackModal(false)
	}
	const handleDiscardChanges = () => {
		dispatch(kycApplicationChangesDiscard({params: {id: envs.profileId}}))
	}
	const confirm = (link: string, general?: boolean) => {
        dispatch(setEditedFormConfirmed({onConfirm: true, onConfirmLink: link === '' ? '/' : link, general, showErrors: false, refId }))
    }
	return (
		<>
			{envs.type === 'standalone' &&
				<HeaderCustom>
					<div className='content'>
						<Row>
							<Col sm={8} md={7} lg={6} xl={5}>
								<ImgLogo src={Logo} alt='Bitclear KYC' />
							</Col>
							<Col sm={8} md={7} lg={10} xl={11}>
								{envs.type === 'standalone' &&
									<MenuCustom theme='dark' style={{ width: 510 }} onClick={onClick} mode="horizontal" items={items(messagesCount, envs.admin)} />
								}
							</Col>
							<Col sm={8} md={10} lg={8} xl={8}>
								<div style={{color: themeColor.gray, textAlign: 'right'}}>
									<span style={{color: '#000'}}>Hello, {envs.admin ? camelCaseToName(adminProfile.profile?.username) : envs.accountType === 'BUSINESS' ? kycApplication.values.name : kycApplication.values.firstName ? kycApplication.values.firstName : kycApplication.values.lastName ? kycApplication.values.lastName : 'there'}</span>
									<span> | </span>
									<a onClick={handleLogoutClick}><b>Logout</b></a>
								</div>
							</Col>
							
						</Row>
					</div>
				</HeaderCustom>
			}
			{envs.admin && envs.type === 'standalone' && 
				<KycMessages />
			}
			{envs.type === 'standalone' && envs.admin && nav.step !== -1 &&
				<>
					<NavCustom style={{top: messagesCount ? 122 : 65}}>
						<div className='content'>
							<Row justify='space-between'>
								<Col>
									<ButtonUpperTabs onClick={() => confirm('/summary')}  className={navEl.nav.navSubpage === 'kyc' ? 'btn-sm active' : 'btn-sm'} btnType='text'>KYC</ButtonUpperTabs>
								
									<ButtonUpperTabs onClick={() => confirm('/business-profile')} className={navEl.nav.navSubpage === 'business-profile' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Business Profile</ButtonUpperTabs>
							
									<ButtonUpperTabs onClick={() => confirm('/risk-profile')} className={navEl.nav.navSubpage === 'risk-profile' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Risk profile</ButtonUpperTabs>

									<ButtonUpperTabs onClick={() => confirm('/relation')}  className={navEl.nav.navSubpage === 'relation' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Business Relation</ButtonUpperTabs>

								</Col>
								<Col>
									<p style={{marginTop: 5}}>
										<b className='client'>{envs.isBusiness ? kycApplication.values.name : <>{kycApplication.values?.firstName ? kycApplication.values?.firstName : 'New'} {kycApplication.values?.lastName ? kycApplication.values?.lastName : 'Person'}</>}</b> | 
										<Text type={relationStatus ? "success" : "secondary"}><b> {relationStatus ? 'Active' : 'Not active'}</b></Text>
										{!relationStatus && <> | <Text type={profileArchived ? 'secondary' : statusInfo === 'SUBMITTED' || statusInfo === 'ACCEPTED' ? "success" : statusInfo === 'NEW' ? "secondary" : "secondary"}><b> KYC {profileArchived ? 'Archived' : statusInfo === 'CLARIFICATION_NEEDED' ? 'Clarification needed' : statusInfo} </b></Text></>}
										{statusInfo && <ActionMenuDropdown statusInfo={statusInfo} businessRelation={relationStatus} handleDropMenuClick={handleDropMenuClick} />}
									</p>
								</Col>
							</Row>
						</div>
					</NavCustom>
				</>
			}
			{!envs.admin && envs.type === 'standalone' && nav.step !== -1 &&
				<KycMessages />
			}
			<FormSaver />
			<div style={{'height': '100%'}}>
				{envs.admin && adminProfile.profile.adminId === '' && adminProfileLoaded.isFullfilled || adminProfileLoaded.isError ? (
					<Error403 />
				) : (
					<PageContent>		
						{listingView === true ? (
							<Row gutter={30}>
								{children}
							</Row>) : ( 	
							<>	    
							<Row gutter={30}>
							    <Col sm={24} md={hideSteps ? 24 : 12} lg={hideSteps ? 24 : 18} xl={hideSteps ? 24 : 18} id='pageWrapper' style={{marginBottom: envs.admin ? '150px' : '70px'}}>
							    	<Space direction='vertical' size={0} style={{'marginBottom': '15px'}}>
							    		<Space direction='vertical' size={'middle'}>
								    	{pageName && <PageName>{pageName}</PageName>}
								      	{heading ? <Title level={1}>{heading}</Title> : <></>}
								      </Space>
								    	{children}
									</Space>
									{!hideNav &&
										<Fixed className={((status === 'ACCEPTED') && step === 0) ? 'no-stick' : ''} style={{'bottom': `${envs.admin ? '57px' : '0'}`}}>
											<KycNav approve={approve} archive={archive} restore={restore} validationAction={validationAction} validationActionParams={validationActionParams} hideValidate={hideValidate} hideSaveButton={hideSaveButton} hideApproveStepButton={hideApproveStepButton} setErrorsListing={setErrorsListing} />
										</Fixed>
									}
									
							     </Col>
							     {!hideSteps &&
									<Col sm={24} md={12} lg={6} xl={6} style={{marginBottom: envs.admin ? '110px' : '30px'}}>
										<Sticky style={{'top': envs.admin && envs.type === 'standalone' ? '130px' : '40px'}}>
											<div>
												<Space direction='vertical' size={30}>
													<Space direction='vertical' size={'middle'}>
														<Title level={5}>KYC steps</Title>
													<KycSteps adminView={adminView} />
													</Space>
												
											</Space>
										</div>
										</Sticky>
									</Col>
								 }
								</Row>
								{envs.admin &&
									<Fixed style={{bottom: 0}}>
										<FooterCustomModule style={{'bottom':'0', 'borderTop': `2px solid ${themeColor.grayWhite}`}}>
											<Row style={{zIndex: 1, background: '#fff', padding: '10px 30px', marginLeft: '-30px', marginRight: '-30px', justifyContent: 'space-between'}}>
								            	<Col span={8}>
										            <FooterDrawer type='message' />
										        </Col>
								            	<Col span={8}>
										            <FooterDrawer type='note' />
										        </Col>
								            </Row>
							            </FooterCustomModule>
						            </Fixed>
								}
								</>
						)}
					</PageContent>
				)}
			</div>
			<SendBackModal open={showSendBackModal} handleOk={handleSendBack} handleCancel={() => setShowSendBackModal(false)} />
			<DiscardChangesModal open={showDiscardModal} handleOk={handleDiscardChanges} handleCancel={() => setShowDiscardModal(false)} />
			<RejectKYCModal open={rejectModalOpen} handleOk={handleRejectConfirm} handleCancel={handleRejectCancel} />
			<DeleteClientModal open={deleteModalOpen} handleOk={handleDeleteConfirm} handleCancel={handleDeleteCancel} />
		</>
		
	)
}

export default KycLayout
