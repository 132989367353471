
import { MessagesResponse } from '../slices/adminInboxSlice'
import { createAsyncThunk } from "@reduxjs/toolkit";
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Paths } from "api/kyc/generated/client2";

type PathParameters = Paths.GetMessages.QueryParameters

export const adminMessagesGet: AsyncThunkActionType<
  MessagesResponse[],
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("adminMessagesGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api2.getMessages(null, null, data?.config);
    return response.data as MessagesResponse[];
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});

