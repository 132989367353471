import { createSlice } from "@reduxjs/toolkit";
import { Components } from "../../../api/kyc-backend/generated/client";
import { adminProfileGet } from "../asyncThunks/adminProfileGet";
import { adminProfilesGet } from "../asyncThunks/adminProfilesGet";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";
import { reduce } from "lodash";

export type AdminResponse = Components.Schemas.AdminProfile;

type State = {
  profile: AdminResponse,
  profiles: AdminResponse[]
};

const initialState: State = {
  profile: {
    adminId: '',
    username: ''
  },
  profiles: []
};

type CaseReducers<State> = {
  adminProfileGetClear: CaseReducerType<State, object>;
};

export const adminSlice = createSlice<State, CaseReducers<State>>({
  name: "admin",
  initialState,
  reducers: {
    adminProfileGetClear: (state, { payload }) => {
      state.profile = initialState.profile
    }
  },
  extraReducers: (builder) => {
    builder.addCase(adminProfileGet.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
    builder.addCase(adminProfilesGet.fulfilled, (state, action) => {
      state.profiles = action.payload;
    })
  },
});

export const { adminProfileGetClear } = adminSlice.actions;
export default adminSlice.reducer;
